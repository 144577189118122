// eslint-disable-next-line no-redeclare,no-unused-vars
function checkAdminHotkeys(evt) {
  const {altKey} = window;
  if (altKey && evt.key == 'u') {
    ajax({
      object: 'settings',
      send: {func: 'settingsUpdate'},
    });
  }
}
